// import { Button } from "@/components/ui/button"
import { BadgeCheck, Loader2 } from "lucide-react"
// import ReactPlayer from "react-player"
import MainPageForm from "./components/MainPageForm"
// import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
// import { FaFacebook, FaGlobe, FaLinkedin } from "react-icons/fa";
// import { RiInstagramFill } from "react-icons/ri";
import { useEffect, useRef, useState } from "react"
import Helmet from "react-helmet"
import { useParams, useSearchParams } from "react-router-dom"
import api from "@/hooks/useApi"
import i18n from 'i18next';
// import { useTranslation } from "react-i18next";
import Header from "./components/Header";
// import Footer from "./components/Footer";
import ErrorPage from "./components/ErrorMessage";
import { Button } from "@/components/ui/button"
import ReactPlayer from "react-player"
import Footer from "./components/Footer"
import { Card, CardContent } from "@/components/ui/card"

interface LandingPage {
    uuid: string;
    product: string;
    logo_url: string;
    favicon_url: string;
    colors: {
        primary: string;
        secondary: string;
    };
    content: {
        seo: {
            google_tag: string;
            meta_pixel: string;
            title: string;
            description: string;
            keywords: string;
            canonical_url: string;
            og: {
                site_name: string;
                image: {
                    url: string;
                    width: string;
                    height: string;
                }
            }

        };
        introduction: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            video_url: string;
            button: string;
        };
        features: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            items: {
                icon: string;
                title: string;
                description: string;
            }[];
            button: string;
        };
        benefits: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            items: {
                icon: string;
                title: string;
                description: string;
            }[];
            button: string;
        };
        benchmarking: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            infographic: string;
            button: string;
        };
        form_section: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            form_content: {
                title: string;
                button: string;
            }
        }

    }
}

interface ApiResponse {
    landing_page: LandingPage
    code: number;
    success: boolean;
}

export default function MainPage() {
    const [landingPage, setLandingPage] = useState<LandingPage>()
    const [error, setError] = useState<number>()
    const [loading, setLoading] = useState<boolean>(true)
    const { landing_page_path } = useParams()
    const [searchParams, _] = useSearchParams();
    // const { t } = useTranslation()
    const testimonials = [
        {
            name: "Joana Mendes",
            role: "Especialista em Marketing Digital",
            image: "https://icon-library.com/images/unknown-person-icon/unknown-person-icon-13.jpg",
            stars: 5,
            text: "O EasyTracking mudou completamente a forma como gerencio minhas campanhas. Agora sei exatamente quem acessa meus links, e isso me ajudou a otimizar meu orçamento e aumentar minhas conversões em 40%.",
        },
        {
            name: "Carlos Almeida",
            role: "Afiliado Profissional",
            image: "https://icon-library.com/images/unknown-person-icon/unknown-person-icon-13.jpg",
            stars: 5,
            text: "Antes, eu fazia anúncios sem saber se estava alcançando as pessoas certas. Com o EasyTracking, consigo entender meu público e ajustar as campanhas em tempo real. Não sei como vivia sem isso!",
        },
        {
            name: "Mariana Costa",
            role: "Empreendedora Digital",
            image: "https://icon-library.com/images/unknown-person-icon/unknown-person-icon-13.jpg",
            stars: 5,
            text: "Saber o horário e a localização de quem acessa meus links me deu uma vantagem enorme. Consigo planejar melhor meus anúncios e investir onde tenho mais retorno. É simplesmente incrível!",
        },
    ];

    const sectionRef = useRef<HTMLDivElement>(null);

    const scrollToFormSection = () => {
        if (sectionRef.current) {
            const top = sectionRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: top - 100, behavior: 'smooth' });
        }
    };

    const fetchLandingPage = async (lang: string) => {
        await api.get<ApiResponse>(`/landing-pages/show?landing_page_path=${landing_page_path}&language=${lang}`)
            .then((success) => {
                setLandingPage(success.data.landing_page)
                document.documentElement.style.setProperty('--primary-color', success.data.landing_page.colors.primary);
                document.documentElement.style.setProperty('--secondary-color', success.data.landing_page.colors.secondary);
                i18n.changeLanguage(lang)
            })
            .catch((error) => {
                setError(error.response.data.code)
                return (
                    <ErrorPage error={error.response.data.code} />
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        i18n.changeLanguage('pt-BR')
        fetchLandingPage(i18n.language || 'pt-BR')
    }, [])

    if (loading) {
        return (
            <div className="min-h-screen w-screen flex justify-center items-center">
                <Loader2 className="animate-spin" />
            </div>
        )
    }

    if (!landingPage || error) {
        return (
            <ErrorPage error={error} />
        )
    }

    return (
        <>
            <Helmet>
                <title>{landingPage.content.seo.title}</title>
                <meta name="description" content={landingPage.content.seo.description} />
                <meta name="keywords" content={landingPage.content.seo.keywords} />
                <meta property="og:title" content={landingPage.content.seo.title} />
                <meta property="og:description" content={landingPage.content.seo.description} />
                <meta property="og:url" content={landingPage.content.seo.canonical_url} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={landingPage.content.seo.og.site_name} />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content={landingPage.content.seo.og.image.url} />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content={landingPage.content.seo.og.image.width} />
                <meta property="og:image:height" content={landingPage.content.seo.og.image.height} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={landingPage.content.seo.canonical_url} />
                <link rel="icon" href={landingPage.favicon_url} type="image/x-icon"></link>

                <script>
                    {`
                    if (window.location.hostname === 'pages.brainlegacy.tech') {
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${landingPage.content.seo.google_tag}');
                    }
                    `}
                </script>

                <noscript>
                    {`
                    if (window.location.hostname === 'pages.brainlegacy.tech') {
                        <iframe src="https://www.googletagmanager.com/ns.html?id=${landingPage.content.seo.google_tag}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
                    }
                    `}
                </noscript>

                <script>
                    {`
                    if (window.location.hostname === 'pages.brainlegacy.tech') {
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '${landingPage.content.seo.meta_pixel}');
                        fbq('track', 'PageView');
                    }
                    `}
                </script>

                <noscript>
                    {`
                    if (window.location.hostname === 'pages.brainlegacy.tech') {
                        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${landingPage.content.seo.meta_pixel}&ev=PageView&noscript=1" />
                    }
                    `}
                </noscript>
            </Helmet>
            <Header logo_url={landingPage.logo_url} />
            <main className="overflow-x-hidden flex flex-col items-center min-w-screen justify-start">
                {/* <section
                    style={{
                        color: landingPage.content.introduction.text_color
                    }}
                    className="bg-[#A17FAC36] px-4 py-10 w-full flex flex-col justify-center items-center">
                    <div className="flex flex-col max-w-[80rem] justify-center gap-10 items-center text-[#0A0A0A]">
                        <div className="flex flex-col gap-5 w-full max-w-6xl lg:text-center">
                            <h1
                                style={{
                                    color: landingPage.colors.secondary
                                }}
                                className={'text-3xl xl:text-4xl font-bold'}
                            >
                                Você Sabe Quem Está Clicando Nos Seus Links e Quando? Descubra Agora e Escale Suas Vendas com Dados Precisos em Tempo Real!
                            </h1>
                            <h2 className="text-lg xl:text-xl">
                                Saiba exatamente o horário, a origem e a frequência dos acessos para otimizar suas campanhas e aumentar suas vendas de forma estratégica! Oferta Exclusiva: 6 Meses Grátis – Só Para os Primeiros 100 Usuários!
                            </h2>

                        </div>
                        <div className="flex flex-col gap-4 w-full items-center">
                            <div className="w-full lg:max-w-3xl py-5">
                                <ReactPlayer
                                    className="aspect-video"
                                    url={landingPage.content.introduction.video_url}
                                    width="100%"
                                    height="100%"
                                    controls
                                    preload="auto"
                                />
                            </div>
                            <div className="flex justify-center">
                                <img
                                    className="w-full lg:w-2/3 border border-2 border-black rounded-lg"
                                    src="https://landingpage.ms.brainlegacy.tech/storage/landing_pages/easytracking/easytracking_image_2.png"
                                />
                            </div>
                            <Button
                                style={{
                                    background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                                }}
                                className="py-7 self-center w-full font-semibold max-w-md px-4 text-2xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                                onClick={scrollToFormSection}
                            >
                                Eu quero aproveitar a oferta!
                            </Button>
                        </div>
                    </div>
                </section> */}

                <section className="px-4 py-10 w-full flex justify-center">
                    <div className="flex flex-col max-w-[80rem] justify-center gap-10 items-center text-[#0A0A0A] ">
                        <div className="flex flex-col gap-5 lg:text-center">
                            <h2
                                style={{
                                    color: landingPage.colors.secondary
                                }}
                                className={'text-3xl xl:text-4xl font-bold'}>
                                Quantos Clientes Você Já Perdeu Por Não Saber Quem Clicou Nos Seus Links?
                            </h2>
                            <h3 className="text-lg xl:text-xl">
                                Pare de desperdiçar dinheiro em campanhas às cegas e descubra exatamente quem está clicando, onde e quando.
                            </h3>
                        </div>
                        <div className="flex flex-col gap-8 lg:flex-row lg:gap-10 lg:items-center">
                            <div className="flex justify-center lg:w-1/2">
                                <img
                                    className="w-[20rem] lg:w-[60rem]"
                                    src="https://khealth.com/wp-content/uploads/2021/10/Hero-free-promo.png"
                                />
                            </div>
                            <div className="lg:w-1/2">
                                <ul className="flex flex-col gap-5">
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10 "
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Identifique o momento ideal para converter:</span> Descubra os horários com mais acessos e foque onde há mais chances de vendas.
                                        </p>
                                    </li>
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10"
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Domine as localidades mais promissoras:</span> Saiba de onde vêm os cliques e direcione seus anúncios para quem realmente importa.
                                        </p>
                                    </li>
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10"
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Dados em tempo real, decisões certeiras:</span> Nome, e-mail, telefone, dispositivo utilizado e muito mais – tudo para escalar suas vendas com inteligência.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Button
                            style={{
                                background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                            }}
                            className="py-7 self-center w-full font-semibold max-w-md px-4 text-2xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            Pare de perder clientes!
                        </Button>
                    </div>
                </section>

                <section className="bg-[#A17FAC36] px-4 py-10 w-full flex justify-center">
                    <div className="flex flex-col max-w-[80rem] justify-center gap-10 items-center text-[#0A0A0A] ">
                        <div className="flex flex-col gap-5 lg:text-center">
                            <h2
                                style={{
                                    color: landingPage.colors.secondary
                                }}
                                className={'text-3xl xl:text-4xl font-bold'}>
                                Por Que Usar o EasyTracking Vai Transformar Suas Campanhas?
                            </h2>
                            <h3 className="text-lg xl:text-xl">
                                Com o EasyTracking, você tem o poder dos dados nas suas mãos. Transforme seus links em uma ferramenta estratégica e conquiste resultados reais.
                            </h3>
                        </div>
                        <div className="flex flex-col gap-8 lg:flex-row-reverse lg:justify-between lg:gap-5 lg:items-center">
                            <div className="flex justify-center lg:justify-end lg:w-1/2">
                                <img
                                    className="w-[20rem] lg:w-[35rem]"
                                    src="https://www.maricopa.gov/ImageRepository/Document?documentId=82761"
                                />
                            </div>
                            <div className="lg:w-1/2">
                                <ul className="flex flex-col gap-5">
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10 "
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Escale Suas Vendas:</span> Com informações detalhadas sobre seus acessos, você pode ajustar suas campanhas para obter resultados mais rápidos e eficientes.
                                        </p>
                                    </li>
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10"
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Dados Precisos em Tempo Real:</span> Descubra o nome, e-mail, telefone, localização e até o dispositivo usado por quem acessa seus links.
                                        </p>
                                    </li>
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10"
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Maximize o Retorno do Seu Investimento:</span> Saiba onde e quando seus anúncios geram mais engajamento e foque seus esforços no que realmente funciona.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Button
                            style={{
                                background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                            }}
                            className="py-7 self-center w-full font-semibold max-w-md px-4 text-2xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            Comece a Escalar Suas Vendas!
                        </Button>
                    </div>
                </section>

                <section className="px-4 py-10 w-full flex justify-center">
                    <div className="flex flex-col max-w-[80rem] justify-center gap-10 items-center text-[#0A0A0A] ">
                        <div className="flex flex-col gap-5 lg:text-center">
                            <h2
                                style={{
                                    color: landingPage.colors.secondary
                                }}
                                className={'text-3xl xl:text-4xl font-bold'}>
                                Quer Saber Como o EasyTracking Pode Transformar Seus Links em Estratégias Poderosas?
                            </h2>
                            <h3 className="text-lg xl:text-xl">
                                Em apenas alguns passos simples, você transforma seus links em uma ferramenta estratégica poderosa. Veja como é fácil no vídeo abaixo e comece agora!
                            </h3>
                        </div>
                        <div className="flex flex-col gap-8 lg:flex-row lg:gap-10 lg:items-center">
                            <div className="flex justify-center lg:w-1/2">
                                <ReactPlayer
                                    className="aspect-video"
                                    url={landingPage.content.introduction.video_url}
                                    width="100%"
                                    height="100%"
                                    controls
                                    preload="auto"
                                />
                            </div>
                            <div className="lg:w-1/2">
                                <ul className="flex flex-col gap-5">
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10 "
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Crie seu link em segundos:</span> Acesse o EasyTracking, escolha um nome para o link e insira o destino real para onde ele irá redirecionar.
                                        </p>
                                    </li>
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10"
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Personalize e configure:</span> Decida se deseja capturar dados dos usuários, como nome, e-mail e telefone.
                                        </p>
                                    </li>
                                    <li className="flex gap-3 items-start">
                                        <BadgeCheck
                                            className="text-green-600 min-w-10 min-h-10"
                                        />
                                        <p className="lg:text-lg xl:text-xl">
                                            <span className="font-bold">Compartilhe e acompanhe:</span> Divulgue seu link e acesse todas as informações detalhadas diretamente na plataforma, em tempo real.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Button
                            style={{
                                background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                            }}
                            className="py-7 self-center w-full font-semibold max-w-md px-4 text-2xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            Crie Seu Primeiro Link Agora!
                        </Button>
                    </div>
                </section>

                {/* <section
                    style={{
                        backgroundColor: landingPage.content.features.background_color,
                        color: landingPage.content.features.text_color
                    }}
                    className="w-full flex flex-col items-center">
                    <div className="flex flex-col justify-center items-center gap-5 px-4 py-8 w-full max-w-6xl">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className="text-3xl md:text-3xl lg:text-4xl font-bold">
                            {landingPage.content.features.title}
                        </h2>
                        <h3 className="md:text-lg lg:text-xl mb-10">
                            {landingPage.content.features.subtitle}
                        </h3>
                        <div className="flex justify-center pb-5">
                            <ul className="flex flex-col gap-3 md:grid md:gap-10 md:grid-cols-2 md:items-start md:justify-start">
                                {landingPage.content.features.items.map((item, index) => (
                                    <li key={index} className="flex items-start gap-2 text-start max-w-sm">
                                        <img src={item.icon} className="w-[2rem]" alt="ícone de funcionalidades" />
                                        <p>
                                            <span className="font-medium text-lg">
                                                {item.title}:
                                            </span>
                                            <span>
                                                {" " + item.description}
                                            </span>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <Button
                            style={{
                                background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            {landingPage.content.features.button}
                        </Button>
                    </div>
                </section> */}
                {/* <section
                    style={{
                        backgroundColor: landingPage.content.benefits.background_color,
                        color: landingPage.content.benefits.text_color
                    }}
                    className="w-full flex flex-col items-center">
                    <div className="px-4 flex flex-col justify-center items-center gap-5 py-8 w-full max-w-6xl">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className="text-3xl md:text-3xl lg:text-4xl font-bold">
                            {landingPage.content.benefits.title}
                        </h2>
                        <h3 className="md:text-lg lg:text-xl mb-10">
                            {landingPage.content.benefits.subtitle}
                        </h3>
                        <div className="flex justify-center pb-5">
                            <ul className="flex flex-col gap-3 md:grid md:gap-16 md:grid-cols-2 md:items-start md:justify-start">
                                {landingPage.content.benefits.items.map((item, index) => (
                                    <li key={index} className="flex items-start gap-2 text-start max-w-sm break-words">
                                        <img src={item.icon} className="w-[5rem]" alt="ícone de benefício" />
                                        <p>
                                            <span className="font-medium text-lg">
                                                {item.title}:
                                            </span>
                                            <span>
                                                {" " + item.description}
                                            </span>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Button
                            style={{
                                background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            {landingPage.content.benefits.button}
                        </Button>
                    </div>
                </section>
                {/* <section
                    style={{
                        backgroundColor: landingPage.content.benchmarking.background_color,
                        color: landingPage.content.benchmarking.text_color
                    }}
                    className="w-full flex flex-col items-center">
                    <div className="px-4 space-y-4 py-8 max-w-6xl">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className="text-3xl md:text-3xl lg:text-4xl font-bold">
                            {landingPage.content.benchmarking.title}
                        </h2>
                        <h3 className="md:text-lg lg:text-xl">
                            {landingPage.content.benchmarking.subtitle}
                        </h3>
                        <img src={landingPage.content.benchmarking.infographic} alt={t('alts.infographic')} />
                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            {landingPage.content.benchmarking.button}
                        </Button>
                    </div>
                </section> */}
                <section className="bg-[#A17FAC36] w-full py-10 px-4">
                    <div className="flex flex-col gap-10 container mx-auto max-w-7xl">
                        <div className="flex flex-col gap-5 lg:text-center">
                            <h2
                                style={{
                                    color: landingPage.colors.secondary
                                }}
                                className={'text-3xl xl:text-4xl font-bold'}>
                                O Que Nossos Usuários Estão Dizendo?
                            </h2>
                            <h3 className="text-lg xl:text-xl">
                                Descubra como o EasyTracking já está transformando negócios como o seu.
                            </h3>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {testimonials.map((testimonial, index) => (
                                <Card key={index} className="border-none">
                                    <CardContent className="p-0">
                                        <div className="bg-[#F8F7FF] p-6 rounded-t-lg flex items-center gap-4">
                                            <div className="relative w-12 h-12 rounded-full overflow-hidden">
                                                <img
                                                    src={testimonial.image || "/placeholder.svg"}
                                                    alt={testimonial.name}
                                                    className="object-cover"
                                                />
                                            </div>
                                            <div>
                                                <h3 className="font-semibold text-[#2F1C6A]">{testimonial.name}</h3>
                                                <p className="text-sm text-[#2F1C6A]/70">{testimonial.role}</p>
                                            </div>
                                        </div>
                                        <div className="p-6">
                                            <div className="flex gap-1 mb-4">
                                                {[...Array(testimonial.stars)].map((_, i) => (
                                                    <svg
                                                        style={{
                                                            color: landingPage.colors.primary
                                                        }}
                                                        key={i} className="w-5 h-5 fill-current" viewBox="0 0 20 20">
                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                    </svg>
                                                ))}
                                            </div>
                                            <p className="text-[#2F1C6A]/80 leading-relaxed">{testimonial.text}</p>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>

                        <Button
                            style={{
                                background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                            }}
                            className="py-7 self-center w-full font-semibold max-w-md px-4 text-2xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToFormSection}
                        >
                            Seja o próximo caso de sucesso!
                        </Button>
                    </div>
                </section>

                <section
                    ref={sectionRef}
                    style={{
                        // backgroundColor: landingPage.content.form_section.background_color,
                        color: landingPage.content.form_section.text_color
                    }}
                    className="py-10 px-4 flex flex-col items-center w-full">
                    <div className="flex flex-col items-center gap-5 max-w-[80rem] lg:text-center">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className={'text-3xl xl:text-4xl font-bold'}>
                            Aproveite Agora – 6 Meses Grátis de Controle Total Sobre Seus Links! Descubra Como Dados Precisos Podem Aumentar Suas Vendas Hoje Mesmo!
                        </h2>
                        <h3 className="text-lg xl:text-xl">
                            Preencha o formulário abaixo e, ao se cadastrar, você receberá um email ensinando como acessar o EasyTracking para começar a monitorar seus links, otimizar campanhas e aumentar suas conversões de forma estratégica!
                        </h3>
                        <div className="w-full flex justify-center">
                            <MainPageForm language={i18n.language} colors={landingPage.colors} landing_page_uuid={landingPage.uuid} indicator_uuid={searchParams.get('iu')} utm_source={searchParams.get('utm_source')} utm_medium={searchParams.get('utm_medium')} utm_campaign={searchParams.get('utm_campaign')} product_name={landingPage.product} form_text={landingPage.content.form_section.form_content} />
                        </div>
                    </div>
                </section>
                {/* <section className="py-8 px-4 space-y-4 max-w-6xl">
                    <p className="text-3xl md:text-3xl lg:text-4xl font-bold">
                        Perguntas frequentes
                    </p>
                    <Separator />
                    <Accordion className=" bg-[#0a0972] text-start text-secondary px-4 rounded-xl" type="single" collapsible>
                        <AccordionItem value="item-1" className="max-w-6xl">
                            <AccordionTrigger>Como a maratona pode me ajudar a transformar uma escola comum em uma escola de excelência?</AccordionTrigger>
                            <AccordionContent className="max-w-2xl ">
                                Começa com a consciência e as práticas das Dicas de Ouro, as quais são as primeiras coisas que devem funcionar na escola na busca da excelência.
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    <Accordion className=" bg-[#0a0972] text-start text-secondary px-4 rounded-xl" type="single" collapsible>
                        <AccordionItem value="item-1" className="max-w-6xl">
                            <AccordionTrigger>Como a maratona pode me ajudar a transformar uma escola comum em uma escola de excelência?</AccordionTrigger>
                            <AccordionContent className="max-w-2xl ">
                                Começa com a consciência e as práticas das Dicas de Ouro, as quais são as primeiras coisas que devem funcionar na escola na busca da excelência.
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    <Accordion className=" bg-[#0a0972] text-start text-secondary px-4 rounded-xl" type="single" collapsible>
                        <AccordionItem value="item-1" className="max-w-6xl">
                            <AccordionTrigger>Como a maratona pode me ajudar a transformar uma escola comum em uma escola de excelência?</AccordionTrigger>
                            <AccordionContent className="max-w-2xl ">
                                Começa com a consciência e as práticas das Dicas de Ouro, as quais são as primeiras coisas que devem funcionar na escola na busca da excelência.
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>

                    <Button
                        className="py-6 w-full max-w-[18rem] bg-gradient-to-r from-[#76419B] to-[#4E195E] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300">
                        Conhecer mais!
                    </Button>
                </section>
                <section className="py-8 px-4 gap-5 flex flex-col items-center justify-center">
                    <p className="text-3xl md:text-3xl lg:text-4xl font-bold">
                        {t('contact.title')}
                    </p>
                    <div>
                        <p className="text-lg">{t('contact.description')}</p>
                        <p className="text-lg">
                            {t('contact.light_description')}
                        </p>
                    </div>
                    <div className="space-y-2">
                        <div className="flex gap-3 justify-center text-3xl">
                            <a href="https://www.facebook.com/profile.php?id=100084199213436" target="_blank" aria-label="Ir para o Facebook da BrainLegacy Technology">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/brainlegacytech" target="_blank" aria-label="Ir para o Instagram da BrainLegacy Technology">
                                <RiInstagramFill />
                            </a>
                            <a href="https://www.brainlegacy.tech" target="_blank" aria-label="Ir para o site da BrainLegacy Technology">
                                <FaGlobe />
                            </a>
                            <a href="https://www.linkedin.com/company/brainlegacytech" target="_blank" aria-label="Ir para o LinkedIn da BrainLegacy Technology">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </section>

                <section className="py-8 px-4 space-y-4 max-w-6xl">
                    <p className="text-3xl lg:text-4xl font-bold">
                        {t('company.title', { landingPage })}
                    </p>
                    <p className="text-lg">
                        {t('company.light_description')}
                    </p>
                    <a href="https://www.brainlegacy.tech" target="_blank" rel="noreferrer">
                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="mt-4 py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                        >
                            {t('company.more_button')}
                        </Button>
                    </a>
                </section> */}

            </main >
            <Footer logo_url="https://landingpage.ms.brainlegacy.tech/storage/blt_logo.webp" />
        </>
    )
}